import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { ExternalProvider, Web3Provider } from '@ethersproject/providers'
import { store } from '../store'
import { UnlockModal } from '../components/unlock/unlockModal'
import Header from '../components/Header'
import Footer from '../components/Footer'
import 'semantic-ui-css/semantic.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../assets/index.scss'
import 'react-toastify/dist/ReactToastify.css'
import { Account } from '../components/Account'
import '../assets/style.css'
import { ToastContainer } from 'react-toastify'
import { getListEntitys } from '../api/endpoints'

console.info("Project Version: 0.4")

function getLibrary(provider: ExternalProvider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

function MyApp({ Component, pageProps, galleries }: any) {
    return (
        <Provider store={store}>
            <Web3ReactProvider getLibrary={getLibrary}>
                <>
                    <Account />
                    <Header galleries={galleries} seoData={pageProps?.seoData} />
                    <Component {...pageProps} />
                    <UnlockModal />
                    <Footer />
                    <ToastContainer />
                </>
            </Web3ReactProvider>
        </Provider>
    )
}

MyApp.getInitialProps = async () => {
    const galleries = await getListEntitys('galleries')
    return {
        galleries: galleries.gallerys.map((gallery: { title: string; slug: string }) => {
            return {
                title: gallery.title,
                slug: gallery.slug,
            }
        }),
    }
}

export default MyApp
