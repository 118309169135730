import { Dispatch } from 'redux'
import { toast } from 'react-toastify'
import { getAccBalance } from '../../web3-lib/api'
import { metamask, torus, walletconnect } from '../../web3-lib/connectors'
import { setContext } from './actions'
import { web3ContextTypes } from './reducer'
import { LOCALSTORAGE_WALLET } from '../../constants/constants'

const connectors: any = {
    MetaMask: metamask,
    WalletConnect: walletconnect,
    Torus: torus,
}

export const handleSetContext = (context: web3ContextTypes) => async (dispatch: Dispatch<any>) => {
    try {
        const balance = await getAccBalance(context)
        dispatch(setContext({ context, balance }))
    } catch (error) {
        dispatch(setContext({ context, balance: null }))
    }
}

export const handleActivate = (name: string) => async (_: Dispatch<any>, getState: () => any) => {
    const ctx = getState().web3Context.context

    let isError = false

    ctx.activate(connectors[name], (err: Error) => {
        if (err) {
            isError = true
            localStorage.removeItem(LOCALSTORAGE_WALLET)
            toast.error(err.message)
        }
    }).then(() => {
        if (!isError) localStorage.setItem(LOCALSTORAGE_WALLET, JSON.stringify(name))
    })
}

export const handleDisconnect = () => async (dispatch: Dispatch<any>, getState: () => any) => {
    const ctx = getState().web3Context.context
    ctx.deactivate()
    dispatch(handleSetContext({ active: false }))
    localStorage.removeItem(LOCALSTORAGE_WALLET)
}
