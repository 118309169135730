import React from 'react'
import styles from './styles.module.scss'
import Link from 'next/link'

interface MobileMenuLinksTypes {
    url: string | null
    isDropDown?: boolean
    label: string
    links?: { url: string; label: string }[]
}

interface MobileMenuTypes {
    links: MobileMenuLinksTypes[]
    isOpened: boolean
    pathname: string
    setMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export const MobileMenu = ({ links, isOpened, pathname, setMobileMenuOpened }: MobileMenuTypes) => {
    const renderLinks = () =>
        links.length > 0 &&
        links.map((link, key) => {
            if (!link.isDropDown) {
                return (
                    <Link key={key} href={link.url ?? ''}>
                        <a
                            className={`${styles.menu__list__link} ${
                                pathname === link.url ? styles.menu__list__active : ''
                            }`}
                            onClick={()=>setMobileMenuOpened(false)}
                        >
                            {link.label}
                        </a>
                    </Link>
                )
            } else {
                return (
                    link.links &&
                    link.links.map((item, key) => (
                        <Link key={key} href={item.url}>
                            <a
                                className={`${styles.menu__list__link} ${
                                    pathname === item.url ? styles.menu__list__active : ''
                                }`}
                                onClick={()=>setMobileMenuOpened(false)}
                            >
                                {item.label}
                            </a>
                        </Link>
                    ))
                )
            }
        })

    return (
        <div data-testid="mobile-menu" className={`${styles.menu} ${isOpened ? styles.menu__opened : ''}`}>
            <div className={styles.menu__list}>{renderLinks()}</div>
        </div>
    )
}
