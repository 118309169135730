import React from 'react'
import styles from './styles.module.scss'
import { Button } from 'semantic-ui-react'
import { getUserWalletTypeFromLocalStorage } from '../../web3-lib/helper'
import { connectorNames } from '../../store/web3Context/constants'
import { handleActivate, handleDisconnect } from '../../store/web3Context/handlers'
import { useDispatch } from 'react-redux'
import { web3ContextTypes } from '../../store/web3Context/reducer'

export const Unlock = ({ closeModal, context }: { closeModal: () => void; context: web3ContextTypes }) => {
    const dispatch = useDispatch()

    const connect = (name: string) => {
        dispatch(handleActivate(name))
        closeModal()
    }

    const disconect = () => {
        dispatch(handleDisconnect())
        closeModal()
    }

    const renderButtons = () =>
        Object.keys(connectorNames).map((name: string) => {
            let url = ''
            switch (name) {
                case 'MetaMask':
                    url = require('../../assets/images/icn-metamask.svg')
                    break
                case 'WalletConnect':
                    url = require('../../assets/images/walletConnectIcon.svg')
                    break
                case 'Torus':
                    url = require('../../assets/images/trustWallet.png')
                    break

                default:
                    break
            }

            return (
                <div key={name} className={styles.modal__content__item}>
                    <Button
                        basic
                        color="black"
                        onClick={() => {
                            connect(name)
                        }}
                        className={styles.modal__content__item__button}
                        disabled={!!context.active}
                    >
                        <span>
                            <img className={styles.modal__content__item__icon} src={url} alt={name} />
                            {name}
                            {name === getUserWalletTypeFromLocalStorage() && (
                                <div className={styles.modal__content__item__active} />
                            )}
                        </span>
                    </Button>
                </div>
            )
        })
    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.modal__content}>
                    {renderButtons()}
                    <div className={styles.modal__content__dis}>
                        <Button
                            color="black"
                            className={styles.modal__content__dis__button}
                            onClick={disconect}
                            disabled={!context.active}
                        >
                            Deactivate
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
