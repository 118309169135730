import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getUserWalletTypeFromLocalStorage } from './helper'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { metamask, torus, walletconnect } from './connectors'
import { handleSetContext } from '../store/web3Context/handlers'
import { LOCALSTORAGE_WALLET } from '../constants/constants'

const connectors: any = {
    MetaMask: metamask,
    WalletConnect: walletconnect,
    Torus: torus,
}

let firstLogin = true

const rpcs = [1, 4, 22]

export const useAuth = () => {
    const ctx = useWeb3React()
    const dispatch = useDispatch()

    useEffect(() => {
        const { ethereum }: any = window

        const handleChainChanged = (id: string) => {
            const currentWalletType = getUserWalletTypeFromLocalStorage()
            const chaindId = parseInt(id)

            if (currentWalletType) {
                if (rpcs.indexOf(chaindId) === -1) {
                    toast.error('inccorect network')
                    ctx.deactivate()
                    dispatch(handleSetContext({ active: false }))
                    return false
                } else
                    !ctx.active &&
                        ctx.activate(connectors[currentWalletType], undefined, true).catch((error) => {
                            console.error('Failed to activate after chain changed', error)
                        })
            }
        }

        const handleAccountsChanged = () => {
            const currentWalletType = getUserWalletTypeFromLocalStorage()
            ctx.active &&
                ctx.activate(connectors[currentWalletType], undefined, true).catch((error) => {
                    console.error('Failed to activate after chain changed', error)
                })
        }

        ethereum && ethereum.on('chainChanged', handleChainChanged)
        ethereum && ethereum.on('accountsChanged', handleAccountsChanged)

        return () => {
            if (ethereum && ethereum.removeListener) {
                ethereum.removeListener('chainChanged', handleChainChanged)
                ethereum.removeListener('accountsChanged', handleAccountsChanged)
            }
        }
    }, [ctx])

    useEffect(() => {
        if (!ctx.active && firstLogin) {
            const currentWalletType = getUserWalletTypeFromLocalStorage()
            if (currentWalletType) {
                ctx.activate(connectors[currentWalletType], (err) => {
                    if (err) {
                        localStorage.removeItem(LOCALSTORAGE_WALLET)
                    }
                })
            }
            firstLogin = false
        }

        if (ctx.active && ctx.chainId && rpcs.indexOf(ctx.chainId) === -1) {
            toast.error('inccorect network')
            ctx.deactivate()
            dispatch(handleSetContext({ active: false }))
        }

        dispatch(handleSetContext(ctx))
    }, [ctx, dispatch])

    return {
        ctx,
    }
}
