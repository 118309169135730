let isDataLayerSubmitted = false;

declare global {
    interface Window { dataLayer: any; utmUserData: any }
}

export const utmContentFunc = () => {
    if (typeof window !== "undefined") {
        const utmData = {
            utmType: null,
            utmContentName: null,
            utmContentCompany: null,
        };

        // url parameter acquisition
        const getAllUrlParams = (url?: any) => {
            const isBrowser = typeof window !== "undefined";
            const urlLocation = isBrowser
                ? window.location.search.slice(1)
                : undefined;
            var queryString = url ? url.split("?")[1] : urlLocation;
            var obj: any = {};
            if (queryString) {
                queryString = queryString.split("#")[0];
                var arr = queryString.split("&");
                for (var i = 0; i < arr.length; i++) {
                    var a = arr[i].split("=");
                    var paramNum = undefined;
                    // eslint-disable-next-line no-loop-func
                    var paramName = a[0].replace(/\[\d*\]/, function (v: any) {
                        paramNum = v.slice(1, -1);
                        return "";
                    });
                    var paramValue = typeof a[1] === "undefined" ? true : a[1];
                    paramName = paramName.toLowerCase();
                    try {
                        paramValue = atob(paramValue);
                    } catch (err) {
                        paramValue = paramValue;
                    }
                    if (obj[paramName]) {
                        if (typeof obj[paramName] === "string") {
                            obj[paramName] = [obj[paramName]];
                        }
                        if (typeof paramNum === "undefined") {
                            obj[paramName].push(paramValue);
                        } else {
                            obj[paramName][paramNum] = paramValue;
                        }
                    } else {
                        obj[paramName] = paramValue;
                    }
                }
            }
            return obj;
        };

        // cookie add function
        const setCookie = (cookieName: any, cookieValue: any, days: any) => {
            const isBrowser = typeof window !== "undefined";
            if (!isBrowser) {
                return;
            }
            if (!cookieValue) {
                return;
            }
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            document.cookie =
                cookieName +
                " = " +
                btoa(cookieValue) +
                "; expires = " +
                date.toUTCString();
        };

        // cookie search function
        const getCookie = (name: any) => {
            const isBrowser = typeof window !== "undefined";
            if (!isBrowser) {
                return undefined;
            }
            var matches = document.cookie.match(
                new RegExp(
                    "(?:^|; )" +
                    name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                    "=([^;]*)"
                )
            );
            return matches ? matches[1] : undefined;
        };

        // utmData filling function
        const setUtmData = (utmString: any) => {
            var arr = null;
            if (utmString) {
                let stringUncode = utmString;
                arr = stringUncode.split("|");
            } else {
                let utmCookie = getCookie("va_content");
                if (utmCookie) {
                    utmCookie = atob(utmCookie);
                    arr = utmCookie.split("|");
                }
            }
            if (arr === null) {
                return;
            }
            utmData.utmType = arr[0];
            utmData.utmContentName = arr[1];
            utmData.utmContentCompany = arr[2];
        };

        // utmData stor initialization
        const initUtmStor = () => {
            // 1. get the parameter line from url
            const utmString = getAllUrlParams().utm_term;

            // 2. add to the cookie with parameters utm_term
            setCookie("va_content", utmString, 30);

            // 3. utmData filling
            setUtmData(utmString);

            // 4. parameters from the utmObj are recorded in the dataLayer
            if (typeof window !== "undefined") {
                if (
                    utmData.utmType &&
                    (utmData.utmContentCompany || utmData.utmContentName)
                ) {
                    if (!isDataLayerSubmitted) {
                        window.dataLayer.push({
                            action: `${utmData.utmType}`,
                            value: `${utmData.utmContentCompany}, ${utmData.utmContentName}`,
                        });
                    }
                    isDataLayerSubmitted = true;
                }
                window.utmUserData = utmData;
            }

            return utmData;
        };
        // stor initialization
        try {
            return initUtmStor();
        } catch (err) {
            return
        }
    }
};

export const getUtmUserData = () => {
    let utmUserData = {};

    if (process.browser) {
        utmUserData = window.utmUserData ? window.utmUserData : {};
    }

    return utmUserData;
};
