import React, { useEffect, useState } from 'react'
import { Container, Icon } from 'semantic-ui-react'
import styles from './styles.module.scss'
import Logo from '../../assets/images/logo.svg'
import { MobileMenu } from '../MobileMenu'
import Wallet from './wallet.svg'
import Head from 'next/head'
import Link from 'next/link'
import { gtmScriptText } from '../../assets/scripts/gtmScript'
import { utmContentFunc } from '../../static/assets/utm'
import { chatbotScript } from '../../assets/scripts/chatbotScript'
import { convertETH, cutAccountAddress } from '../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { toggleConnectorModal } from '../../store/web3Context/actions'
import { useRouter } from 'next/router'
import { ContextInitialStateTypes } from '../../store/web3Context/reducer'
import { SeoDataTypes } from '../../typings/seoTypings'
import { useRef } from 'react'
import { useClickOutside } from '../../hooks/clickOutside'

let address: string = ''

const Header = ({ seoData, galleries }: { seoData?: SeoDataTypes; galleries: { title: string; slug: string }[] }) => {
    let links = [
        {
            url: '/artists',
            label: 'Rare Artists',
        },
        {
            url: null,
            isDropDown: true,
            label: 'Galleries',
            links: galleries.map((item) => {
                return { url: `/gallery/${item.slug}`, label: item.title }
            }),
        },
        {
            url: '/auctions',
            label: 'Auctions',
        },
        {
            url: '/collections',
            label: 'Collectibles',
        },
        {
            url: null,
            isDropDown: true,
            label: 'About',
            links: [
                {
                    url: '/contact',
                    label: 'Contact',
                },
                {
                    url: '/mission',
                    label: 'Mission',
                },
                {
                    url: '/submissions',
                    label: 'Submissions',
                },
                {
                    url: '/process',
                    label: 'Process',
                },
            ],
        },
    ]

    const { asPath } = useRouter()
    const dispatch = useDispatch()
    const { context, balance } = useSelector(
        ({ web3Context }: { web3Context: ContextInitialStateTypes }) => web3Context
    )
    const account = context.account
    const [isMobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false)
    const [isUserWalletOpened, setUserWalletOpened] = useState(false)
    const wrapperRef = useRef(null)
    useClickOutside(wrapperRef, () => setUserWalletOpened(false))

    useEffect(() => {
        document.body.style.overflowY = 'scroll'

        if (account) {
            address = cutAccountAddress(account)
        }
    }, [account])

    useEffect(() => {
        if (isMobileMenuOpened) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'scroll'
        }
    }, [isMobileMenuOpened])

    const toggleMobileMenu = () => {
        setMobileMenuOpened(!isMobileMenuOpened)
    }

    const toggleUserWallet = () => setUserWalletOpened(!isUserWalletOpened)

    const renderLinks = () => {
        return links.map((link, key) => <AppLink {...link} key={key} location={asPath} />)
    }

    // Copided from VM3
    utmContentFunc()

    const renderSeoHead = () => {
        if (seoData) {
            return (
                <>
                    {seoData.ogTitle && <meta property="og:title" content={seoData.ogTitle} />}
                    {seoData.ogDescription && <meta property="og:description" content={seoData.ogDescription} />}
                    {seoData.ogImage && <meta property="og:image" content={seoData.ogImage} />}
                    {seoData.ogUrl && <meta property="og:url" content={seoData.ogUrl} />}
                    {seoData.ogType && <meta property="og:type" content={seoData.ogType} />}
                </>
            )
        }
    }

    return (
        <>
            <Head>
                <script type="text/javascript">{gtmScriptText}</script>
                <script type="text/javascript">{chatbotScript}</script>
                <title>{seoData?.ogTitle ?? 'Rare'}</title>
                {renderSeoHead()}
                <link
                    href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <div className={`${styles.header} ${isMobileMenuOpened ? styles.header__fixed : ''}`}>
                <Container>
                    <div className={styles.header__container}>
                        <div className={styles.header__left}>
                            <Link href="/">
                                <a>
                                    <img className={styles.header__left__logo} src={Logo} alt="logo" />
                                </a>
                            </Link>
                            <nav className={styles.header__nav}>{renderLinks()}</nav>
                        </div>

                        <div>
                            <div className={styles.header__nav__buttons}>
                                {/* <button>
                    <img src={Search} alt="site-search" />
                  </button> */}
                                <div
                                    data-testid="toggleUserWallet"
                                    className={styles.header__nav__wallet}
                                    onClick={toggleUserWallet}
                                    ref={wrapperRef}
                                >
                                    <img src={Wallet} alt="user-wallet" />
                                    <div className={styles.header__nav__wallet__badge}>
                                        <div
                                            className={
                                                account
                                                    ? styles.header__nav__wallet__badge__active
                                                    : styles.header__nav__wallet__badge__inactive
                                            }
                                        ></div>
                                    </div>
                                    {isUserWalletOpened && (
                                        <div data-testid="walletInfo" className={styles.header__walletInfo}>
                                            {!account && (
                                                <button
                                                    data-testid="toggleConnectorModal"
                                                    onClick={() => dispatch(toggleConnectorModal())}
                                                    className={styles.header__walletInfo__unlock}
                                                >
                                                    <span></span> Unlock Wallet
                                                </button>
                                            )}
                                            {account && (
                                                <div>
                                                    <button
                                                        data-testid="toggleConnectorModal"
                                                        className={styles.header__walletInfo__wallet}
                                                        onClick={() => dispatch(toggleConnectorModal())}
                                                    >
                                                        <span />
                                                        {address}
                                                    </button>
                                                    <div className={styles.header__walletInfo__balance}>
                                                        {balance ? convertETH(`${balance}`).toFixed(4) : 0.0} ETH
                                                    </div>
                                                    <hr />
                                                    <Link href="/dashboard">
                                                        <a className={styles.header__walletInfo__dashboard}>
                                                            My Purchases
                                                        </a>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <button
                                    data-testid="toggleMobileMenuButton"
                                    onClick={toggleMobileMenu}
                                    className={`${styles.header__menuButton} ${
                                        isMobileMenuOpened ? styles.header__menuButton__active : ''
                                    }`}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <MobileMenu
                links={links}
                pathname={asPath}
                isOpened={isMobileMenuOpened}
                setMobileMenuOpened={setMobileMenuOpened}
            />
        </>
    )
}

interface linksTypes {
    url: string
    label: string
}

interface AppLinkTypes {
    url: string | null
    label: string
    isDropDown?: boolean
    location: string
    links?: linksTypes[]
}

const AppLink = ({ url, label, isDropDown, location, links }: AppLinkTypes) => {
    const [isOpened, toggle] = useState(false)
    const wrapperRef = useRef(null)
    useClickOutside(wrapperRef, () => toggle(false))
    const handleToggleDropdown = () => toggle(!isOpened)
    if (!isDropDown) {
        return (
            <Link href={url ?? ''}>
                <a
                    className={`${styles.header__nav__link} ${
                        location === url ? styles.header__nav__link__active : ''
                    }`}
                >
                    {label}
                </a>
            </Link>
        )
    } else {
        return (
            <span
                ref={wrapperRef}
                data-testid="headerLink"
                onClick={handleToggleDropdown}
                className={styles.header__nav__link}
            >
                {label}

                <Icon name={`${isOpened ? 'angle up' : 'angle down'}`} />
                {isOpened && (
                    <div className={styles.header__nav__link__dropdown}>
                        {links &&
                            links.length > 0 &&
                            links.map((link, key) => (
                                <Link key={key} href={link.url}>
                                    <a
                                        className={
                                            location === link.url ? styles.header__nav__link__dropdown__active : ''
                                        }
                                    >
                                        {link.label}
                                    </a>
                                </Link>
                            ))}
                    </div>
                )}
            </span>
        )
    }
}

export default Header
