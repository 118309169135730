import { createReducer } from '@reduxjs/toolkit'
import { CardInfoContractTypes, CardInfoStrapiTypes } from '../../components/CardInfo/typing'
import { getNftCardContractById, getNftCardStrapiById } from './actions'

export type nftCardTypes = {
    cardList: {
        data: Array<[any]>
    }
    strapiData: {
        data: CardInfoStrapiTypes | false
        isError: boolean
    }
    contractData: {
        data: CardInfoContractTypes | null
        isError: boolean
    }
}

const initialState: nftCardTypes = {
    cardList: {
        data: [],
    },
    strapiData: {
        data: false,
        isError: false,
    },
    contractData: {
        data: null,
        isError: false,
    },
}

export const nftCardsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getNftCardStrapiById, (state, action: { payload?: any; type: string }) => {
            state.strapiData.data = action.payload
        })
        .addCase(getNftCardContractById, (state, action: { payload?: any; type: string }) => {
            state.contractData.data = action.payload
        })
})
