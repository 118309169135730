import React from 'react'
import { Container, Image } from 'semantic-ui-react'
import styles from './styles.module.scss'
import Logo from '../../assets/images/logo-light.svg'
import Link from 'next/link'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <Container>
                <div className={styles.footer__wrapper}>
                    <nav className={styles.footer__topNav}>
                        <Link href="/artists">
                            <a className={styles.footer__topNav__link}>Artists</a>
                        </Link>
                        <Link href="/collections">
                            <a className={styles.footer__topNav__link}>Collections</a>
                        </Link>
                        <Link href="/auctions">
                            <a className={styles.footer__topNav__link}>Auctions</a>
                        </Link>
                        <a
                            href="//blog.rare.market"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.footer__topNav__link}
                        >
                            Rare Blog
                        </a>
                    </nav>
                </div>
                <div className={styles.footer__wrapper}>
                    <div>
                        <Image className={styles.footer__logo} src={Logo} />
                        <p className={styles.footer__copyright}>© 2021 Rare Market LLC</p>
                        <nav className={styles.footer__bottomNav}>
                            <a className={styles.footer__bottomNav__link} href="//blog.rare.market/privacy-policy">
                                Privacy Policy
                            </a>
                            <a className={styles.footer__bottomNav__link} href="//blog.rare.market/terms">
                                Terms of Use
                            </a>
                            <a className={styles.footer__bottomNav__link} href="/contact">
                                Contact
                            </a>
                        </nav>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
