import { createReducer } from '@reduxjs/toolkit'
import { Web3ReactManagerFunctions } from '@web3-react/core/dist/types'
import { setContext, toggleConnectorModal } from './actions'
import { AbstractConnector } from '@web3-react/abstract-connector'

export interface web3ContextTypes {
    active: boolean
    connector?: AbstractConnector
    library?: any
    chainId?: number
    account?: null | string
    error?: Error
}

export interface ContextInitialStateTypes {
    context: web3ContextTypes
    isConnectorsModalOpened: boolean
    balance: null | string
}

const initialState: ContextInitialStateTypes = {
    context: {
        active: false,
    },
    isConnectorsModalOpened: false,
    balance: null,
}

export const web3ContextReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setContext, (state, action: { type: string; payload?: any }) => {
            state.context = action.payload.context
            state.balance = action.payload.balance
        })
        .addCase(toggleConnectorModal, (state) => {
            state.isConnectorsModalOpened = !state.isConnectorsModalOpened
        })
})
