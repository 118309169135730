import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { auctionReducer } from './auction/reducer'
import { auctionHistoryReducer } from './auctionHistory/reducer'
import { nftCardsReducer } from './nftCards/reducer'
import { web3ContextReducer } from './web3Context/reducer'

const rootReducer = combineReducers({
    auctionState: auctionReducer,
    web3Context: web3ContextReducer,
    nftCardState: nftCardsReducer,
    auctionHistory: auctionHistoryReducer
})

export const store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(thunk)))
