import { LOCALSTORAGE_WALLET } from "../constants/constants"

export const getUserWalletTypeFromLocalStorage = () => {
    const currentType = localStorage.getItem(LOCALSTORAGE_WALLET)
    if (currentType) {
        const parsedValue = JSON.parse(currentType)

        if (parsedValue) return parsedValue
    } else return null
}
