import { createReducer } from "@reduxjs/toolkit"
import { setAuctionEventAction, updateAuctionEventsAction } from "./actions"

export interface AuctionHistoryTypes {
    events: any[]
}

const initialState: AuctionHistoryTypes = {
    events: []
}

export const auctionHistoryReducer = createReducer(initialState, builder => {
    builder
        .addCase(setAuctionEventAction, (state, action: { payload?: any; type: string }) => {
            state.events = action.payload.events
        })
        .addCase(updateAuctionEventsAction, (state, action: { payload?: any; type: string }) => {
            state.events = action.payload.events
        })
})