import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { TorusConnector } from "@web3-react/torus-connector";
import config from "../config/config";

export const metamask = new InjectedConnector({
    supportedChainIds: config.injectedChainIds(),
});

export const walletconnect = new WalletConnectConnector({
    rpc: config.rpcUrls(),
    qrcode: config.qrcode,
    pollingInterval: config.pollingInterval,
});

export const torus = new TorusConnector({ chainId: config.torusChainId });
