import React from 'react'
import styles from './styles.module.scss'
import { Modal } from 'semantic-ui-react'
import { Unlock } from './unlock'
import { useDispatch, useSelector } from 'react-redux'
import { toggleConnectorModal } from '../../store/web3Context/actions'
import { ContextInitialStateTypes } from '../../store/web3Context/reducer'

export const UnlockModal = () => {
    const dispatch = useDispatch()
    const { isConnectorsModalOpened, context } = useSelector(
        ({ web3Context }: { web3Context: ContextInitialStateTypes }) => web3Context
    )

    return (
        <Modal
            className={styles.modal}
            open={isConnectorsModalOpened}
            onClose={() => dispatch(toggleConnectorModal())}
            closeIcon={true}
        >
            <Unlock context={context} closeModal={() => dispatch(toggleConnectorModal())} />
        </Modal>
    )
}
