import Web3 from 'web3'
import { web3ContextTypes } from '../store/web3Context/reducer'

export const getAccBalance = async (ctx: web3ContextTypes) => {
    let balance = ''
    if (ctx?.active) {
        const web3 = new Web3(ctx.library.provider)
        if (ctx.account) {
            balance = await web3.eth.getBalance(ctx.account)
        }
    }
    return balance
}

export const getAccNetwork = async (ctx: web3ContextTypes) => {
    let network = 0
    if (ctx?.active) {
        const web3 = new Web3(ctx.library.provider)

        network = await web3.eth.getChainId()
    }
    return network
}
